import React from 'react'
import Create from '../../Components/FormPage/CreatePage/Create'

function CreatePage() {
  return (
      <>
          <Create />
      </>
  )
}

export default CreatePage