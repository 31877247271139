import React from 'react'
import Contact from '../Components/ContactPage/Contact'

function ContactPage() {
  return (
      <>
      <Contact/>
      </>
  )
}

export default ContactPage