import React from 'react'
import Bottom from '../Components/BottomEnd/Bottom'
import Home from '../Components/HomePage/Home'
// import Navbar from '../Components/Navbar/Navbar'

function HomePage() {
    return (
        <> 
            <Home/>
            
        
        </>
  )
}

export default HomePage