import React from 'react'
import View from '../../Components/FormPage/ViewPage/View'

function ViewPage() {
  return (   
      <>
      <View/>
      </>
  )
}

export default ViewPage