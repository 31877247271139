import React from 'react'
import Standardshipping from '../Components/StandardshippingPage/Standardshipping'

function StandardshippingPage() {
  return (
      <>
      <Standardshipping/>
      </>
  )
}

export default StandardshippingPage