import React from 'react'
import About from '../Components/AboutPage/About'

function AboutPage() {
  return (
      <>
      <About/>
      </>
  )
}

export default AboutPage