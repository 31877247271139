import React from 'react'
import Roadtransportation from '../Components/RoadtransportationPage/Roadtransportation'

function RoadtransportationPage() {
  return (
      <>
          <Roadtransportation/>
        </>
  )
}

export default RoadtransportationPage